import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Helmet from "react-helmet";
import Layout from 'components/Layout';
import BlogPost from 'components/pages/BlogPost';
import { Image } from 'components/pages/BlogPost/styled';
import { ImgGatsbyBackground } from 'components/wrappers';
import styled, { css } from 'styled-components';
import { blogPosts } from 'common/blogPostsFeatured';
import BlogBaseCard from 'components/blog/BaseCard';
import BlogSidebar from 'components/blog/BlogSidebar';
import {PostsWrapper} from 'components/pages/Blog/styled';
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share';
import FacebookDefaultIcon from 'images/social-share-icons/facebook-white.png';
import TwitterDefaultIcon from 'images/social-share-icons/twitter-white.png';
import LinkedinDefaultIcon from 'images/social-share-icons/linkedin-white.png';
import BlogOptin from 'components/blog/BlogOptin';
import {Clear, BlogRow, ShareButton, ReadNow, DateText, TitleText, UnorderedList, OrderedList, FirstTitle, BottomShare, SectionFull, OptinSection, ColumnHalf, ColumnHalfLast} from 'components/blog/SingleBlogStyle';

import ShipkooLabel from 'images/blog-images/shipkoo-labels.png';
import ShipkooShippingLabelFeatured from 'images/featured-image/shipping-label-barcode-featured.png';
import BarcodeImg from 'images/blog-images/shipping-label-barcode.png';
import ShipkooSocialMediaImg from 'images/blog-images/shipkoo-social-media-poster.png';


interface OwnProps {
  location: {
    href: string;
  };
}

const BlogArticle = ({ location }: OwnProps) => {
  const data = useStaticQuery(graphql`
    query CreateShippingLabelImg {
      file(relativePath: { eq: "shipping-label-barcode.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const BackgroundImage = () => <ImgGatsbyBackground fluid={data.file.childImageSharp.fluid} alt="how to create a shipping label"/>;
  const { href } = location;
  const intl = useIntl()
  return (
    <Layout>
      <Helmet 
        // title="How to Create a Shipping Label | Shipkoo"
        title={intl.formatMessage({ id: "blog-post-meta-title-how-to-create-a-shipping-label" })}
         meta={
          [
            {
              name: `description`,
              content: "",
            },
            {
              property: `og:title`,
              // content: "How to Create a Shipping Label",
              content: intl.formatMessage({ id: "blog-post-meta-title-how-to-create-a-shipping-label" }),
            },
            {
              property: `og:description`,
              content: "",
            },
            {
              property: `og:image`,
              content: ShipkooShippingLabelFeatured,
            },
            {
              property: `og:type`,
              content: `website`,
            },
            {
              name: `twitter:card`,
              content: `summary`,
            },
          ] as JSX.IntrinsicElements['meta'][]
        }
      />
      <BlogPost
        location={location}
        id="how-to-create-a-shipping-label"
        title="How to Create a Shipping Label"
        date="2020-08-27"
        author="Joe Zhang"
        Background={BackgroundImage}
      >
        <BlogRow>
          <BlogSidebar/>
          <div className="column-half last">

            <p>
              Our ultimate goal at Shipkoo is to simplify your shipping for eCommerce platforms and marketplaces. Just like what our slogan: We Are the Next Generation of Fulfillment and Shipping Solutions - Smarter, Faster, Easier. Your products can be diverse and complicated, but your logistics does not have to be. Shipkoo handles your company’s shipments in a smarter way and you do not need to worry about back-end issues.
            </p>

            <Image src={BarcodeImg} className="" alt="shipping label barcode"/>

            <p>
              Shipping labels are a significant part of the shipping process. They provide important information needed for a delivery to arrive at its destination. Shipkoo’s Order Management System (OMS) automates and streamlines the process of creating shipping labels and tracking packages. Our user-friendly dashboard allows you to create a shipping label that further simplifies the process of shipping goods to your customers. Shipkoo makes the process of creating a shipping label very fast and easy. You will be able to better understand how to generate shipping labels without any hassle through this guide. 
            </p>

            <h5>
              <strong>
                What is a Shipping Label?
              </strong>
            </h5>

            <p>
              Shipping labels are a type of identification label that helps display the key information for a carrier to transport a package from their start destination (your warehouse) to its end destination (your customer's hands). Each label is unique and you cannot reuse old labels. Shipping labels differ depending on the carrier you use, but they all consist of information like addresses, names, weight, and a tracking barcode.
            </p>

            <h5>
              <strong>
                What needs to be included in a shipping label?
              </strong>
            </h5>

            <Image src={ShipkooLabel} className="" alt="shipkoo label"/>

            <p>
              As seen above, shipping labels generally include the following information
            </p>

            <UnorderedList>
              <li>
                Origin address
              </li>
              <li>
                Destination address
              </li>
              <li>
                Shipping class 
              </li>
              <li>
                Package weight
              </li>
              <li>
                Tracking number 
              </li>
              <li>
                Shipping barcode
              </li>
            </UnorderedList>

            <p>
              A shipping label displays the information to direct packages to the correct destinations, track them throughout each stage, and ensure the correct service is provided.
            </p>

            <h5>
              <strong>
                Process of creating a shipping label
              </strong>
            </h5>

            <p>
              Shipkoo makes the process of creating a shipping label incredibly easy and time-saving. 
            </p>

            <OrderedList>
              <li>
                Create a new shipment order<br />
                Click on Create Order for shipment and enter the required information for your package. 
              </li>
              <li>
                Select Shipping Rate<br />
                After entering all the required information, you may compare rates across our shipping options/class, and choose the one best fit your shipment.
              </li>
              <li>
                Download Shipping Labels<br />
                After you are done checking off the labels you need, you may simply click Download Label and you may keep it for reference or send it to your customers.
              </li>
            </OrderedList>

            <Image src={ShipkooSocialMediaImg} className="" alt="shipkoo social media poster"/>

            <BottomShare>
              <div className="column-half">
                <p>Share the article</p>
              </div>
              <div className="column-half last">
                <FacebookShareButton url={href}>
                  <ShareButton><img src={FacebookDefaultIcon} alt="Facebook Icon" /> <span>Share</span></ShareButton>
                </FacebookShareButton>
                <TwitterShareButton url={href}>
                  <ShareButton><img src={TwitterDefaultIcon} alt="Twitter Icon" /> <span>Tweet</span></ShareButton>
                </TwitterShareButton>
                <LinkedinShareButton url={href}>
                  <ShareButton><img src={LinkedinDefaultIcon} alt="Linked Icon" /> <span>Share</span></ShareButton>
                </LinkedinShareButton>
              </div>
              <Clear></Clear>
            </BottomShare> 
          </div>  
          <Clear></Clear>
       </BlogRow> 
      </BlogPost>
      <SectionFull>
        <BlogRow>
          <h2>More From Shipkoo</h2>
          <PostsWrapper>
            {blogPosts.map(post => (
              <BlogBaseCard key={`${post.title}-${post.id}`} post={post} />
            ))}
          </PostsWrapper>
        </BlogRow>
      </SectionFull>
      <BlogOptin />
    </Layout>
  );
};

export default BlogArticle;